import Vue from 'vue';
import Vuex from 'vuex';
// import moment from 'moment-timezone';
import i18n from '@/i18n';
import { getPaymentImage, getCreditCardImage } from '@/utils/utils';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isSSOReady: false,
    ssoAppId: null,
    showLogout: false,
    systemStatus: null,
    // defaultPointLogo: require('@/assets/img/i_point.png'),
    isRouterResolved: false, // route轉場動畫...不work...
    token: null,
    ssoInitialized: false,
    isLoggedIn: false,
    user: {},
    userPoints: [],
    userCoupons: [],
    userCards: [],
    orderInfo: {}, // 利用token取得訂單資訊 （付款前）
    // paidOrderInfo: {},  // 利用orderId取得訂單資訊 （付款完）
    amount: 0, // 訂單金額
    paid: 0, // 實付金額
    supportedPaymentMethods: [],
    supportedPoints: [], // 店家支援點數類型
    defaultPointLogo: require('@/assets/icon/pointO.svg'),
    defaultPointLogoW: require('@/assets/icon/pointW.svg'),
    selectedPoint: null,
    selectedCoupon: null,
    selectedCard: null,
    discounts: [],
    isPointsLoaded: false,
    isTicketsLoaded: false,
    showUserCarrier: false,
    shopSettings: null,
    thirdPartyMember: null,
  },
  mutations: {
    updateShopSettings(state, settings) {
      if (settings) {
        state.shopSettings = {...settings};
      } else {
        state.shopSettings = null;
      }
    },
    updateSystemStatus(state, data) {
      state.systemStatus = data;
    },
    updateSSOAppId(state, id) {
      state.ssoAppId = id;
      state.isSSOReady = true;
    },
    updateIsRouterResolved(state, resolved) {
      state.isRouterResolved = resolved;
    },
    updateToken(state, token) {
      state.token = token;
      localStorage.setItem('ddpw:token', token);
    },
    updateSSOInitState(state, status) {
      state.ssoInitialized = status;
    },
    updateIsLoggedIn(state, loggedIn) {
      state.isLoggedIn = loggedIn;
    },
    updateUser(state, user) {
      state.user = {...user};
      console.log('[store] updateUser', user)
    },
    updateUserPoints(state, points) {
      console.log('[store] updateUserPoints', points);
      
      state.userPoints = [...points];
      state.isPointsLoaded = true;
    },
    updateUserCoupons(state, coupons) {
      state.userCoupons = [...coupons];
      state.isTicketsLoaded = true;
    },
    updateOrderInfo(state, order) {
      state.orderInfo = Object.assign({}, order);
    },
    // updatePaidOrderInfo(state, order) {
    //   state.paidOrderInfo = Object.assign({}, order);
    // },
    updateAmount(state, amount) {
      state.amount = amount;
      localStorage.setItem('ddpw:amount', amount);
    },
    updateSupportedPaymentMethods(state, methods) {
      state.supportedPaymentMethods = [...methods];
    },
    updateSupportedPoints(state, points) {
      state.supportedPoints = [...points];
    },
    updateUserCards(state, cards) {
      state.userCards = [...cards];
    },
    updateSelectedCard(state, card) {
      if (card) {
        state.selectedCard = {...card};
      } else {
        state.selectedCard = null;
      }
    },
    updateSelectedPoint(state, point) {
      if (point) {
        state.selectedPoint = {...point};
      } else {
        state.selectedPoint =  null
      }
    },
    updateSelectedCoupon(state, coupon) {
      if (coupon) {
        state.selectedCoupon = {...coupon};
      } else {
        state.selectedCoupon = null
      }
    },
    updateDiscounts(state, discounts) {
      state.discounts = [...discounts];
    },
    updateShowUserCarrier(state, show) {
      state.showUserCarrier = show;
    },
    updateShowLogout(state, show) {
      state.showLogout = show;
    },
    updateThirdPartyMember(state, member) {
      state.thirdPartyMember = member;
    }
  },
  actions: {},
  getters: {
    getUserName(state) {
      return state.user && state.user.displayName 
        ? state.user.displayName 
        : ( state.thirdPartyMember?.name ?  state.thirdPartyMember.name : null);
    },
    getUserId(state) {
      return state.user && state.user.userId 
        ? state.user.userId 
        : state.thirdPartyMember?.uuid ? state.thirdPartyMember.uuid : null;
    },
    getMemberId(state) {
      if (
        state.user &&
        state.user.user &&
        state.user.user._json &&
        state.user.user._json.oid
      ) {
        const oid = state.user.user._json.oid;
        let temp = oid.split('/');
        return temp[temp.length - 1];
      } else {
        return null;
      }
    },
    getAmount(state) {
      return state.amount || localStorage.getItem('ddpw:amount');
    },
    getShopId(state) {
      // console.log('get shop id: ', state.orderInfo)
      return state.orderInfo && state.orderInfo.shopId
        ? state.orderInfo.shopId
        : null;
    },
    getShopName(state) {
      return state.orderInfo && state.orderInfo.shopName
        ? state.orderInfo.shopName
        : null;
    },
    // isTokenExpired(state) {
    //   const currentTs = moment().unix();
    //   if (state.orderInfo && state.orderInfo.expirationTs) {
    //     if (state.orderInfo.expirationTs >= currentTs) {
    //       return false;
    //     } else {
    //       return true;
    //     }
    //   } else {
    //     return false;
    //   }
    // },
    // 會員在此特店可以使用的點數
    getMerchantId(state) {
      return state.isLoggedIn 
        ? state.user.user._json.merchantId 
        : (state.thirdPartyMember?.merchantId ? state.thirdPartyMember.merchantId : null);
    },
    getSupportedPaymentMethods(state) {
      console.log(
        'state.supportedPaymentMethods',
        JSON.stringify(state.supportedPaymentMethods)
      );
      // let methods = [
      //   {
      //       paymentService: 'offline',
      //       paymentType: 'offline',
      //       isBrowserSupported: true,
      //       isEnabled: 1,
      //       name: i18n.t('Offline Payment'),
      //       paymentServiceId: -1,
      //       icon: null,
      //       label: i18n.t('Offline Payment')
      //     }
      // ];
      let disablePaymentServices = state.systemStatus?.disablePaymentServices || [];
      let methods = [];
      state.supportedPaymentMethods.forEach((method) => {
        console.log(
          `${method.paymentService}-${method.paymentType} ${method.isEnabled}`
        );
        let obj = { ...method };
        const pIcon = getPaymentImage(obj.paymentService, obj.paymentType);
        obj.icon = pIcon.icon;
        obj.iconW = pIcon.iconW;
        if (disablePaymentServices.length && disablePaymentServices.includes(method.paymentService)) {
          obj.isSystemDisabled = true;
        } else {
          obj.isSystemDisabled = false;
        }
        obj.label = obj.icon ? null : i18n.t('Credit Card');
        methods.push(obj);
      });

      console.log(
        '[store] getSupportedPaymentMethods:',
        JSON.stringify(methods)
      );
      return methods;
    },
    // 會員在此特店可以使用的點數
    getAvailablePointTypes(state) {
      let available = [];
      console.log('[store]getAvailablePointTypes',state.supportedPoints?.length, state.userPoints?.length);
      // 計算優惠券折抵後應付金額
      let initDiscount = 0;
      const discountAmount = state.discounts.reduce(
        (accumulator, current) => accumulator + current.amount,
        initDiscount
      );
      const payableAmount = state.amount - discountAmount;
    
      state.supportedPoints.forEach((point) => {
        const found = state.userPoints.find(
          (p) => p.pointCode === point.pointCode
        );
        console.log('[store] found supported point type:' + JSON.stringify(found));
        if (found) {
          found.pointStyle = point.pointStyle;
          found.pointsToCurrency = point.pointsToCurrency || 
          {
            currency: 1,
            points: 1
          };
          // 去掉小數部分
          found.redeemableDollars = Math.trunc(
            (found.pointBalance / point.pointsToCurrency.points) *
              point.pointsToCurrency.currency
          );
          found.pointLogo = point.pointLogo || state.defaultPointLogo;
          found.pointLogoW = point.pointLogoW || state.defaultPointLogoW;
          found.entityId = point.entityId;
          found.entityType = point.entityType;
          let maxAmount = Math.min(found.redeemableDollars, payableAmount) || 0
          found.usingPoints =  Math.ceil((maxAmount / point.pointsToCurrency.currency) * point.pointsToCurrency.points) ;
          console.log('[store] found supported point type:' + JSON.stringify(found));
          available.push(found);
        }
        
      });
      console.log('available point types:' + JSON.stringify(available))
      return available;
    },
    getUserPoints: state => state.userPoints,
    // 特店可以使用的點數
    getShopSupportedPointTypes: state => state.supportedPoints,
    // 會員在此特店尚未開通的點數
    getApplicablePointTypes(state) {
      let applicable = [];
      state.supportedPoints.forEach((point) => {
        const found = state.userPoints.find(
          (p) => p.pointCode === point.pointCode
        );
        console.log('found:', found);
        if (!found) {
          if (!point.pointLogo) {
            point.pointLogo = state.defaultPointLogo;
          }
          if (!point.pointLogoW) {
            point.pointLogoW = state.defaultPointLogoW;
          }
          applicable.push(point);
        }
      });
      return applicable;
    },
    getSelectedPoint: state => state.selectedPoint,
    getUserCoupons (state) {
      // const availableBgColors = [null, "#96BE0B", "#22798C"];
      // const bgColorLength = availableBgColors.length;
      // expand all tickets
      let coupons = [];
      state.userCoupons.forEach((ticketProduct) => {
        ticketProduct.tickets.forEach((ticket) => {
          let _coupon = {...ticketProduct};
          delete _coupon.tickets;
          _coupon.merchantId = ticketProduct.merchantId;
          _coupon.ticketProductId= ticketProduct.productId;
          _coupon.ticketBarcodeMessage = ticket.barcodeMessage;
          _coupon.ticketStatus = ticket.status;
          _coupon.ticketId = ticket.ticketId;
          _coupon.ticketUpdatedTs = ticket.updatedTs;
          _coupon.ticketSelected = ticket.selected || false;
          _coupon.quotation = ticket.quotation;  //計算完折扣後的訂單金額
          // _coupon.ticketBgColor = availableBgColors[coupons.length % bgColorLength];
          coupons.push(_coupon);
        });
      });
      return coupons;
    },
    getSelectedCoupon: state => state.selectedCoupon,
    getUserCards (state) {
      return state.userCards.map( card => {
        card.icon = getCreditCardImage(card.cardType);
        card.cardNo = `**** ${card.cardLast4}`;
        return card;
      });
    },
    getSelectedCard: state => state.selectedCard,
    getDiscounts: state => state.discounts,
    getTicketDiscounts(state) {
      let initDiscount = 0;
      const discountAmount = state.discounts.reduce(
        (accumulator, current) => accumulator + current.amount,
        initDiscount
      );
      return discountAmount;
    },
    getShopSettings: state => state.shopSettings,
    getPayableAmount: (state) => {
      // 票券折抵
      let initDiscount = 0;
      const discountAmount = state.discounts.reduce(
        (accumulator, current) => accumulator + current.amount,
        initDiscount
      );
      // 點數折抵
      let pointDiscount = 0;
      pointDiscount = state.selectedPoint?.usingPoints &&
      state.selectedPoint.pointsToCurrency
          ? (state.selectedPoint.usingPoints /
          state.selectedPoint.pointsToCurrency.points) *
          state.selectedPoint.pointsToCurrency.currency
          : state.selectedPoint?.usingPoints
          ? state.selectedPoint?.usingPoints
          : 0;
      
      const payableAmount = state.amount - discountAmount - pointDiscount;
      return payableAmount;
    },
    getThirdPartyMemberShip: (state) => {
      return state.thirdPartyMember;
        // {
        //   "memberId": 47,
        //   "uuid": "38a079aa-def4-a60e-c5a6-263112248b",
        //   "oauthId": "U38a079aadef4a60ec5a6263112248b35",
        //   "picture": "https://profile.line-scdn.net/0hNwmZpXdREVhQIzrAyWRuD2xmHzUnDRcQKEJdOHIlGmt1RFIHOEFXPSUiH2x4EFIHaBZebiErSW4p",
        //   "idno": "H223292882",
        //   "name": "Kay Lai",
        //   "birthdate": "1988/03/05",
        //   "phoneNumber": "0921185084",
        //   "carrier": null,
        //   "gender": 1,
        //   "email": "clearsky0305@gmail.com",
        //   "fullAddress": "桃園市平鎮區",
        //   "isTyCitizen": 1,
        //   "createdTs": 1647945287,
        //   "merchantId": "ttpush"
        // };
    }
  },
  modules: {},
});
