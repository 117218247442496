// import axios from "axios";
export function fetchSystemStatus() { 
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_DDPW_HOST}/system-status`
  }
  return window.qcsso.request(config);
}

export function fetchSystemNotice() { 
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_DDPW_HOST}/system-notice`
    // url: `http://localhost:3000/system-notice`
  }
  return window.qcsso.request(config);
}

export function fetchOrderInfo(token, querystring) { // by token
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_DDPW_HOST}/orders/${token}`,
    params: querystring
  }
  // console.log('fetchOrderInfo', config);
  return window.qcsso.request(config);
}

export function fetchOrderInfoByOrderId(orderId, querystring) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_DDPW_HOST}/orders/${orderId}/info`,
    params: querystring
  }
  // console.log('fetchOrderInfoByOrderId', config);
  return window.qcsso.request(config);
}

export function fetchStoreServices(shopId) {
  var config = {
    method: "get",
    // url: `${process.env.VUE_APP_COREAPI_HOST}/stores/v1/shops/${shopId}`,
    url: `${process.env.VUE_APP_DDPW_HOST}/shops/${shopId}/services`,
    params: {
      services: 'sso,points,ddpay',
      app: 'ddpay'
    }
  }
  console.log('fetchStoreSsoAppId', config);
  return window.qcsso.request(config);
  // return axios.request(config);
}

// 取得特店SSO APP ID
export function fetchStoreSsoAppId(shopId) {
  var config = {
    method: "get",
    // url: `${process.env.VUE_APP_COREAPI_HOST}/stores/v1/shops/${shopId}`,
    url: `${process.env.VUE_APP_DDPW_HOST}/shops/${shopId}`,
    params: {
      services: 'sso',
      app: 'ddpay'
    }
  }
  console.log('fetchStoreSsoAppId', config);
  return window.qcsso.request(config);
  // return axios.request(config);
}

// 取得特店支援的點數
export function fetchSupportedPoints(shopId) {
  var config = {
    method: "get",
    // url: `${process.env.VUE_APP_COREAPI_HOST}/stores/v1/shops/${shopId}`,
    url: `${process.env.VUE_APP_DDPW_HOST}/shops/${shopId}`,
    params: {
      services: 'points',
      app: 'ddpay'
    }
  }
  // console.log('fetchSupportedPoints', config);
  return window.qcsso.request(config);
}

// 取得會員可用點數
export function fetchUserPoints(partnerCode, merchantId, userId, queryString) {
  var config = {
    method: "get",
    // url: `${process.env.VUE_APP_COREAPI_HOST}/points/v1/merchants/${merchantId}/members/${userId}/points`,
    url: `${process.env.VUE_APP_DDPW_HOST}/merchants/${merchantId}/members/${userId}/points`,
    params: queryString
  }
  if (partnerCode) {
    config.url =  `${process.env.VUE_APP_DDPW_HOST}/pms/${partnerCode}/merchants/${merchantId}/members/${userId}/points`;
  }
  // console.log('fetchUserPoints', config);
  return window.qcsso.request(config);
}

// 取得錯誤代碼
export function fetchErrorCode(code, queryString) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_DDPW_HOST}/web-errors/${code}`,
    params: queryString
  }
  return window.qcsso.request(config);
}

// 取得綁定的信用卡
export function fetchSavedCards(merchantId, userId, queryString) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_DDPW_HOST}/merchants/${merchantId}/members/${userId}/saved-cards`,
    params: queryString
  }
  return window.qcsso.request(config);
}

// 取得會員可用優惠券
export function fetchUserCoupons(partnerCode, merchantId, userId, queryString) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_DDPW_HOST}/merchants/${merchantId}/members/${userId}/tickets`,
    params: queryString
  }
  if (partnerCode) {
    config.url = `${process.env.VUE_APP_DDPW_HOST}/pms/${partnerCode}/merchants/${merchantId}/members/${userId}/tickets`;
  }
  return window.qcsso.request(config);
}

// 取得優惠券報價
export function fetchCouponQuotation(partnerCode, merchantId, userId, ticketId, queryString) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_DDPW_HOST}/merchants/${merchantId}/members/${userId}/tickets/${ticketId}/quotation`,
    params: queryString
  }
  if (partnerCode) {
    config.url = `${process.env.VUE_APP_DDPW_HOST}/pms/${partnerCode}/merchants/${merchantId}/members/${userId}/tickets/${ticketId}/quotation`;
  }
  return window.qcsso.request(config);
}


// 試算消費回饋
export function fetchDDCRewards(merchantId, orderId,  queryString) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_DDPW_HOST}/merchants/${merchantId}/orders/${orderId}/ddc-rewards`,
    params: queryString
  }
  return window.qcsso.request(config);
}

// 取得第三方會員資料
export function fetchThirdPartyMemberInfo(partnerCode, userId, queryString) {
  var config = {
    method: "get",
    url: `${process.env.VUE_APP_DDPW_HOST}/pms/${partnerCode}/members/${userId}`,
    // url: `https://apis-dev.ddc.ai/identities/v1/pms/${partnerCode}/members/${userId}`,
    params: queryString
  }
  return window.qcsso.request(config);
}